<template>
  <header id="header">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 overflow">
          <div class="social-icons pull-right">
            <ul class="nav nav-pills">
              <li>
                <a href=""><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href=""><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href=""><i class="fa fa-google-plus"></i></a>
              </li>
              <li>
                <a href=""><i class="fa fa-dribbble"></i></a>
              </li>
              <li>
                <a href=""><i class="fa fa-linkedin"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar navbar-inverse" role="banner" style="border-bottom: 1px solid lightgray;">
      <div class="container">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>

          <a class="navbar-brand" href="javascript: void(0)">
            <h1><img src="images/logo.gif" alt="logo" width="150px"/></h1>
          </a>
        </div>
        <div class="collapse navbar-collapse">
          <ul class="nav navbar-nav navbar-right">
            <li class="active"><router-link to="/">Home</router-link></li>
            <li class="dropdown"><a href="#">GDM Service <i class="fa fa-angle-down"></i></a>
                            <ul role="menu" class="sub-menu">
                                <li><router-link to="/services/risk">Risk prediction</router-link></li>
                                <li><router-link to="/services/treatment">Treatment modality</router-link></li>
                                <li><router-link to="/services/timing" style="text-transform: none;">Timing of Insulin Therapy</router-link></li>
                            </ul>
                        </li>        
            <li><router-link to="/document">Documentation</router-link></li>
            <li><router-link to="/about">About</router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style>
</style>