<template>
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <h6>ABOUT</h6>
          <p>
            The website provides convenient access to comprehensive, accurate
            and efficient prediction of GDM profile.
          </p>
          <br />
          <p>
            Xiangya School of Pharmaceutical Sciences, Central South University.
            All rights reserved.
          </p>
        </div>
        <div class="col-md-3" style="margin-top: 20px">
          <a href="https://www.revolvermaps.com/livestats/5e25vuixr1k/"
            ><img
              src="//rf.revolvermaps.com/h/m/a/0/ff0000/128/25/5e25vuixr1k.png"
              width="256"
              height="128"
              alt="Map"
              style="border: 0"
          /></a>
        </div>

        <div class="col-md-5">
          <p class="copyright-text text-right">
           <i class="el-icon-message"></i> E-mail: jiedong@csu.edu.cn<br /><br>
            Copyright © 2022 All Rights Reserved by Artificial
            Intelligence & Drug Design Group.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>