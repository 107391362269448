import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$axios = axios
axios.defaults.baseURL = 'https://gdm.ddai.tech';
// axios.defaults.baseURL = 'http://127.0.0.1:8001';
router.afterEach(() => {
  window.scrollTo(0, 0);
});//跳转到页面顶部

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
