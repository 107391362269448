import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { title: "Home", showBread: true, }
  },
  {
    path: '/services',
    name: "services",
    redirect: '/services/risk',
    component: { render: (e) => e("router-view") },
    meta: { title: 'GDM SERVICE' },
    children: [{
      path: 'risk',
      name: 'risk',
      component: () =>
        import('../views/RiskPrediction.vue'),
      meta: { title: 'Risk prediction' },
    },
    {
      path: 'risk_result',
      name: 'risk_result',
      component: () => import('../views/result.vue'),
      meta: { title: 'Risk prediction > Results' }
    },
    {
      path: 'treatment',
      name: 'treatment',
      component: () =>
        import('../views/Treatment.vue'),
      meta: { title: "Treatment" }
    },
    {
      path: 'timing',
      name: 'timing',
      component: () =>
        import('../views/Timing.vue'),
      meta: { title: "Timing" }
    },
    ]
  },
  {
    path: '/document',
    name: 'document',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Document.vue'),
    meta: { title: "Document", showBread: true, }
  },
  {
    path: '/model',
    name: 'submit_model',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/SubmitModel.vue'),
    meta: { title: "Submit model", showBread: true, }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue'),
    meta: { title: "About", showBread: true, }
  },
  // {
  //   path: '/result',
  //   name: 'result',
  //   component: () => import('../views/result.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
