<template>
  <div class="container">
  <el-breadcrumb separator-class="el-icon-arrow-right" class="p-5">
    <!-- to为点击跳转 title为路由中的meta属性定义的标题 -->

    <el-breadcrumb-item
      v-for="(item, index) in breadList"
      :key="index"
      :to="{ path: item.path }"
      >{{ item.meta.title }}</el-breadcrumb-item
    >
  </el-breadcrumb>
</div>
</template>
 
<script>
export default {
  data() {
    return {
      breadList: [],
    };
  },
  watch: {
    $route() {
      //监听$route
      this.getBreadcrumb();
    },
  },
  methods: {
    getBreadcrumb() {
      // let matched = this.$route.matched;也可以使用，效果一样
      let matched = this.$route.matched.filter((item) => item.name); //$route.matched 将会是一个包含从上到下的所有对象 (副本)。
      const first = matched[0];
      if (first && first.name !== "home") {
        //$route.name当前路由名称 
        matched = [].concat(matched);
      }
      this.breadList = matched;
    },
  },
  created() {
    this.getBreadcrumb();
  },
};
</script>
<style>
/* 面包屑导航 */
.el-breadcrumb {
  box-sizing: border-box;
  height: 50px;
  font-size: 20px!important;
  /* margin-left: 10%; */
  /* margin-right: 10%; */
  /* background: rgb(231, 231, 231); */
  /* border: 1px solid rgb(141, 55, 105); */
  border-radius: 10px;
}
.el-breadcrumb__separator {
  color: #171717;
}
</style>
